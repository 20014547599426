import React from "react"

import Layout from "../utils/layout"
import SEO from "../utils/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      <a href="/course">KLICK HERE</a> to get back to the fun.
    </p>
  </Layout>
)

export default NotFoundPage
